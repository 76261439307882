













































import Vue from "vue";
import SearchComponent from "@/components/search/SearchComponent.vue";
import MenuButton from "@/components/layout/MenuButton.vue";

export default Vue.extend({
  name: "SearchHeader",
  components: {
    SearchComponent,
    MenuButton,
  },
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      expanded: !this.fixed,
      scrollPosition: 0,
    };
  },
  watch: {
    fixed() {
      this.expanded = !this.fixed;
    },
  },
  computed: {
    focusedElement(): Element | null {
      return document.activeElement;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    this.$el.addEventListener("focusin", this.focusChanged);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.updateScroll);
    this.$el.removeEventListener("focusin", this.focusChanged);
  },

  methods: {
    updateScroll() {
      const trashhold = 10; // px
      if (this.scrollPosition + trashhold < window.scrollY) {
        this.expanded = false;
        this.scrollPosition = window.scrollY;
      } else if (this.scrollPosition > window.scrollY) {
        this.scrollPosition = window.scrollY;
      }
    },
    focusChanged(event) {
      if (this.fixed) {
        // get the focused element
        const el = event.target;
        // get the searchbar element
        const searchbar = this.$refs["searchBar"] as Vue;
        if (!searchbar) return;
        // check if the searchbar contains the focused element
        const focused = searchbar.$el.contains(el);
        if (focused) {
          this.expanded = true;
        }
      }
    },
  },
});
