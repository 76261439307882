































































































































































































































































































































import Vue from "vue";
import Header from "@/components/layout/SearchHeader.vue";
import { getLocale } from "@/i18n";

export default Vue.extend({
  name: "Privacy",
  components: {
    Header,
  },
  metaInfo: {
    title: "Datenschutz - Einander Helfen",
    link: [{ rel: "canonical", href: "https://einander-helfen.org/privacy" }],
  },
  methods: {
    getLocale: getLocale,
  },
});
